import React from 'react';
import './App.css';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

let videoRef = React.createRef();

const audioCtx = new AudioContext();
const audioDest = audioCtx.createMediaStreamDestination();

/**
 * @type {Object.<string, MediaStreamAudioSourceNode>}
 */
let connectedSources = {};

let mediaStreams = new MediaStream();
let currentMediaRecorder = null;
let recorderDataChuncks = [];

window.sampleTest = () => { console.log(recorderDataChuncks) };

/**
 * @typedef {Object} AppState
 * @property {boolean} recordDisabled
 * @property {boolean} stopDisabled
 * @property {boolean} downloadDisabled
 * @property {MediaDeviceInfo[]}  audioDevices
 * @property {string}  selectedRecordingDeviceId
 */


class App extends React.Component {

  constructor(props) {
    super(props);

    /**
     * @type {AppState}
     */
    this.state = {
      recordDisabled: false,
      stopDisabled: true,
      downloadDisabled: true,
      audioDevices: [],
      selectedRecordingDeviceId: null
    }

    navigator.mediaDevices.ondevicechange = this.onDeviceChange.bind(this);
  }

  componentDidMount() {
    this.requestPermissons();
    this.refreshDeviceList();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="mt-5">
          <div className="d-flex flex-column align-items-center m-1">
            <div>
              <Button
                className="mr-1"
                variant="contained"
                type="button"
                startIcon={<Icon>fiber_manual_record</Icon>}
                disabled={this.state.recordDisabled}
                onClick={this.onRecordClick.bind(this)}>Record</Button>
              <Button
                className="mr-1"
                variant="contained"
                type="button"
                startIcon={<Icon>stop</Icon>}
                disabled={this.state.stopDisabled}
                onClick={this.onStopRecord.bind(this)}>Stop</Button>
              <Button
                variant="contained"
                type="button"
                startIcon={<Icon>save_alt</Icon>}
                disabled={this.state.downloadDisabled}
                onClick={download}>Download</Button>
            </div>
            <div>
              <video className="preview" poster="/images/background/black.png" ref={videoRef} autoPlay />
            </div>
          </div>
          <div className="m-1">
            <div>
              <div>
                <strong><Icon className="align-text-bottom">mic</Icon> MIC</strong>
              </div>
              <div className="m-1">
                <FormGroup>

                  {this.state.audioDevices.map(x =>
                    <FormControlLabel
                      key={x.deviceId}
                      label={x.label}
                      control={<Checkbox disabled={this.state.recordDisabled} onChange={this.onRecordingDeviceChange.bind(this, x.deviceId)} />}
                    />)
                  }
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  onRecordClick() {
    this.startCapture().then(() => {

      mediaStreams.addTrack(audioDest.stream.getAudioTracks()[0]);

      recorderDataChuncks = [];

      currentMediaRecorder = new MediaRecorder(mediaStreams, {
        mimeType: "video/webm; codecs=vp8"
      });
      currentMediaRecorder.ondataavailable = function (e) {
        if (e.data.size > 0) {
          recorderDataChuncks.push(e.data);
        }
      }

      currentMediaRecorder.start(1000);

      this.setState({
        recordDisabled: true,
        stopDisabled: false,
        downloadDisabled: true
      });
    });
  }

  onStopRecord() {
    if (currentMediaRecorder && currentMediaRecorder.state != 'inactive') {
      currentMediaRecorder.stop();
    }

    this.setState({
      recordDisabled: true,
      stopDisabled: true,
      downloadDisabled: true
    });

    setTimeout(() => {

      if (mediaStreams) {

        const tracks = mediaStreams.getTracks();

        if (tracks && tracks.length) {

          tracks.forEach(t => {

            if (t.readyState != 'ended') {
              t.stop();
            }

            mediaStreams.removeTrack(t);
          })
        }
      }

      this.setState({
        recordDisabled: false,
        stopDisabled: true,
        downloadDisabled: false
      });
    }, 2000);
  }

  /**
   * 
   * @param {string} deviceId 
   */
  onRecordingDeviceChange(deviceId) {

    const connectedSrc = connectedSources[deviceId];

    if (!connectedSrc) {
      window.navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: deviceId } } }).then(s => {
        const src = audioCtx.createMediaStreamSource(s);
        connectedSources[deviceId] = src;

        src.connect(audioDest);        
      });
    } else {
      connectedSrc.disconnect(audioDest);
      connectedSources[deviceId] = null;
    }
  }

  async  startCapture() {
    const captureStream = await navigator.mediaDevices.getDisplayMedia({
      video: true
    });

    videoRef.current.srcObject = captureStream;
    captureStream.getVideoTracks().forEach(t => mediaStreams.addTrack(t));
    return captureStream;
  }

  onDeviceChange() {
    this.refreshDeviceList();
  }

  refreshDeviceList() {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      this.setState({
        audioDevices: devices.filter(x => x.kind == 'audioinput' && x.label && x.label.length)
      })
    })
  }

  requestPermissons() {

    navigator.mediaDevices.getUserMedia({ audio: true }).then().catch((err) => {

    });

    navigator.mediaDevices.getUserMedia({ video: true }).then().catch((err) => {

    });
  }


}


function download() {

  var blob = new Blob(recorderDataChuncks, {
    type: "video/webm"
  });
  var url = URL.createObjectURL(blob);
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  const now = new Date();
  a.download = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}.webm`;
  a.click();
  window.URL.revokeObjectURL(url);
}


export default App;
